import { createAction } from '@reduxjs/toolkit';
import { INewsResult } from '../../../model/news';

export const fetchFirstPageNewsRequestAction = createAction('FETCH_FIRST_PAGE_NEWS_REQUEST');
export const fetchFirstPageNewsSuccessAction = createAction<INewsResult>('FETCH_FIRST_PAGE_NEWS_SUCCESS');
export const fetchFirstPageNewsErrorAction = createAction<{ message: string }>('FETCH_FIRST_PAGE_NEWS_ERROR');

export const fetchNextPageNewsRequestAction = createAction('FETCH_NEXT_PAGE_NEWS_REQUEST');
export const fetchNextPageNewsSuccessAction = createAction<INewsResult>('FETCH_NEXT_PAGE_NEWS_SUCCESS');
export const fetchNextPageNewsErrorAction = createAction<{ message: string }>('FETCH_NEXT_PAGE_NEWS_ERROR');

export const resetNewsAction = createAction<{ loading: boolean }>('RESET_NEWS_TICKER');

export const setNewsAllConcepts = createAction('SET_NEWS_ALL_CONCEPTS');

export const setNewsWorking = createAction<{ working: boolean }>('SET_NEWS_WORKING');

export const botResponseChunkAction = createAction<{ chunk: string, timestamp: number }, 'SET_XPERTBOT_CHUNK'>('SET_XPERTBOT_CHUNK');
export const botResponseEndAction = createAction<{ response: string, request_id: string }, 'SET_XPERTBOT_END'>('SET_XPERTBOT_END');
export const resetBotRequestAction = createAction('RESET_BOT_REQUEST');