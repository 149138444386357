import { Btn, IconBtn, Bx, Hamburger } from '@curry-group/mui-curcuma';
import { HeaderHeadbarContainer } from '../headbar-container';
import { faPlus, faSearch, faSlidersV, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTheme from '@material-ui/core/styles/useTheme';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useHistory } from 'react-router-dom';
import {
  changeQueryTermAction,
  changeQueryTypeAction,
  changeQueryCategoriesAction,
  changeQuerySortAction,
  changeQuerySearchTypeAction,
  hideFilterAction,
  resetSearchObjectAction,
  showFilterAction,
  fetchFilterRequestAction,
  setSelectedSearchAction,
  changeQueryDestinationAction,
  setBotSearchAction
} from '../../../data/actions/filter';
import { clearSearchEntriesAction, fetchFirstPageSearchEntriesRequestAction, setSearchWorkingAction } from '../../../data/actions/search';
import { useFilterState } from '../../../data/sagas/filter';
import { flowSteps, getReferrer, MTOLogo } from '../../../helper';
import { ISearchRequestBody } from '../../../model/search/SearchRequest';
import { HeaderFilter } from '../../filter';
import { debounce as _debounce } from 'lodash';
import { Collapse, Grid } from '@material-ui/core';
import { INavbarOpenProps } from '../../navbar';
import { setNavbarOpenAction } from '../../../data/actions/foundation';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { HeadBar } from '../../head-bar';
import { HeadSearchInput } from '../../head-search-input';
import { MobileHeadBar } from '../../mobile-head-bar';
import { NavBarLogo } from '../../navbar-logo';
import { botResponseEndAction, fetchFirstPageNewsRequestAction, setNewsAllConcepts, setNewsWorking } from '../../../data/actions/news';
import { faArrowUp, faMessageBot } from '@fortawesome/pro-solid-svg-icons';

export interface IDashboardHeaderProps {
  routeToSearch: boolean;
  alias: string;
  filterItem: any;
  filterAlias: string;
  filterVisible: boolean;
  filterWorking: boolean;
  filterSwitch?: boolean;
  placeholder?: string;
  searchResult?: any;
  searchObject?: ISearchRequestBody;
  searchTypes?: Array<string>;
  selectedSearch?: string;
  changeQueryTerm?: (term: string, noFilterAction?: boolean) => void;
  changeQueryTypeAction?: (thingType: string) => void;
  changeQueryDestinationAction?: (destinationAlias: string) => void;
  changeQueryCategoriesAction?: (category: any, noToggle: boolean, parentCategoryId: string) => void;
  changeQuerySortAction: (sortId: string) => void;
  changeQuerySearchTypeAction?: (searchTypeId: string) => void;
  resetSearchObject?: () => void;
  setSelectedSearch?: (searchAlias: string, keepQuery?: boolean) => void;
  showFilterAction?: () => void;
  hideFilterAction?: () => void;
  fetchFirstPageSearchEntries?: () => void;
  debouncedFetchFirstPageSearchEntries?: () => void;
  debouncedBotFetchFirstPageSearchEntriesAction?: () => void;
  setSearchWorkingAction?: (working: boolean) => void;
  canCreateContent?: boolean;
  createContentCaption?: string;
  onCreateContentClicked?: () => void;
  memoryList?: boolean;
  memoryIds?: Array<string>;
  setNewsAllConcepts?: () => void;
  newsAllConcepts?: boolean;
  botSearch?: boolean;
  setBotSearchAction?: () => void;
  setBotResponseAction?: (botAnswer: string, botRequestId: string) => void,
  botAnswer?: string;
}

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
  alias,
  filterItem,
  filterAlias,
  routeToSearch,
  filterVisible,
  filterWorking,
  filterSwitch,
  placeholder,
  searchObject,
  searchTypes,
  selectedSearch,
  searchResult,
  changeQueryTerm,
  changeQueryTypeAction,
  changeQueryDestinationAction,
  changeQueryCategoriesAction,
  changeQuerySortAction,
  changeQuerySearchTypeAction,
  showFilterAction,
  hideFilterAction,
  fetchFirstPageSearchEntries,
  debouncedFetchFirstPageSearchEntries,
  debouncedBotFetchFirstPageSearchEntriesAction,
  setSearchWorkingAction,
  resetSearchObject,
  setSelectedSearch,
  canCreateContent,
  createContentCaption,
  onCreateContentClicked,
  memoryList,
  memoryIds,
  setNewsAllConcepts,
  newsAllConcepts,
  botSearch,
  setBotSearchAction,
  setBotResponseAction,
  botAnswer
}) => {
  const theme: any = useTheme();
  const match = useRouteMatch();
  const history = useHistory();
  const pathSplit = history.location.pathname.split('/');
  const onSearchRoute = pathSplit[pathSplit.length - 1] === 'search';
  const onDashboardRoute = pathSplit[pathSplit.length - 1] === 'dashboard';
  const onStartRoute = history.location.pathname === '/start';

  const initialSearchRef = useRef(onSearchRoute);
  const initiallyEmpty = useRef(false);

  useEffect(() => {
    if ((!memoryIds || memoryIds?.length === 0) && !initiallyEmpty.current) {
      initiallyEmpty.current = true;
    }
  });

  useEffect(() => {
    if (initialSearchRef.current && searchObject && filterItem) {
      initialSearchRef.current = false;
      fetchFirstPageSearchEntries?.();
    }
  }, [initialSearchRef, fetchFirstPageSearchEntries, searchObject, filterItem]);

  const searchInactive = initiallyEmpty.current && !!memoryList && (!memoryIds || memoryIds?.length === 0);
  const botSearchActive = !!filterItem?.content?.botSearchActive;

  return (
    <>
      <HeadBar
        transparent={false}
        backButtonLink={
          onSearchRoute || onDashboardRoute
            ? () => {
                const referrer = getReferrer(history.location.search);
                if (referrer) {
                  if (alias === 'news' || alias === 'magazine') {
                    changeQueryTerm?.('');
                    if (botSearch) {
                      setBotResponseAction?.('', '');
                    }
                    resetSearchObject?.();
                    
                    setSearchWorkingAction?.(true);
                    debouncedFetchFirstPageSearchEntries?.();
                    history.push(referrer);
                  } else {
                    history.push(referrer);
                  }
                } else {
                  resetSearchObject?.();
                  history.push(match.url);
                }
              }
            : undefined
        }
      >
        <HeaderHeadbarContainer>
        {!searchInactive && <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
            <HeadSearchInput
              searchIcon={!!botSearch && <FontAwesomeIcon icon={faMessageBot} />}
              input={{
                id: 'head-search',
                value: searchObject?.queryTerm,
                placeholder: alias === 'start'
                  ? filterItem?.content?.title || 'Medtec Online durchsuchen'
                  : !!botSearch
                    ? 'Bot suchen lassen'
                    : placeholder || 'Durchsuchen',
                onChange: e => {
                  const val = e.target.value;
                  if (botSearch && !!botAnswer && val?.length > 0) {
                    setBotResponseAction?.('', '');
                  }
                  changeQueryTerm?.(val, !!botSearch);
                  if (!onSearchRoute && routeToSearch) {
                    let searchUrl = match.url + '/search';
                    if (alias === 'news' && val.length > 0) {
                      searchUrl += '?ref=/news';
                    }
                    history.push(searchUrl);
                  }
                  if ((!botSearch || val?.length === 0) && val !== undefined && (val?.length > 1 || val?.length === 0)) {
                    debouncedFetchFirstPageSearchEntries?.();
                  }
                },
                onKeyPress: e => {
                  if (!!botSearch && e.key === 'Enter') {
                    setSearchWorkingAction?.(true);
                    debouncedBotFetchFirstPageSearchEntriesAction?.()
                  }
                }
              }}
              inputLabel={{ htmlFor: 'head-search' }}
              clearOnClick={() => {
                const referrer = getReferrer(history.location.search);
                if (referrer) {
                  if (alias === 'news' || alias === 'magazine') {
                    changeQueryTerm?.('');
                    if (botSearch && !!botAnswer) {
                      setBotResponseAction?.('', '');
                    }
                    if (!botSearch) {
                      resetSearchObject?.();
                    }
                    setSearchWorkingAction?.(true);
                    debouncedFetchFirstPageSearchEntries?.();
                    if (!botSearch) {
                      history.push(referrer);
                    }
                  } else {
                    history.push(referrer);
                  }
                } else {
                  changeQueryTerm?.('', !!botSearch);
                  if (botSearch && !!botAnswer) {
                    setBotResponseAction?.('', '');
                  }
                  debouncedFetchFirstPageSearchEntries?.();
                  if (!onSearchRoute && routeToSearch) {
                    history.push(match.url + '/search');
                  }
                }
              }}
            />
          </Bx>}
          {!searchInactive && <Bx>
            {!botSearch && <Btn
              size="large"
              startIcon={<FontAwesomeIcon icon={faSlidersV} />}
              onClick={() => {
                if (filterVisible) {
                  hideFilterAction?.();
                } else {
                  showFilterAction?.();
                }
              }}
              color={filterVisible ? 'primary' : 'default'}
              variant={filterVisible ? 'contained' : 'outlined'}
            >
              Filtern
            </Btn>}
            {(alias === 'news' || alias === 'magazine') && botSearchActive && <>
              {botSearch && <IconBtn
                variant="contained" shape="circular"
                style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                size="medium"
                onClick={() => {
                  setSearchWorkingAction?.(true);
                  setBotResponseAction?.('', '');
                  debouncedFetchFirstPageSearchEntries?.();
                }}
                title='Abschicken'
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </IconBtn>}
              <Btn
                style={{ marginLeft: 8 }}
                size="large"
                startIcon={!!botSearch ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faMessageBot} />}
                onClick={() => {
                  if (!botSearch && !!botSearchActive) {
                    hideFilterAction?.();
                    resetSearchObject?.();
                    if (!onSearchRoute && routeToSearch) {
                      history.push(match.url + '/search');
                    }
                  }
                  setBotSearchAction?.();
                  changeQueryTerm?.('', !botSearch && !!botSearchActive);
                  if (botSearch && !!botAnswer) {
                    setBotResponseAction?.('', '');
                  }
                  setSearchWorkingAction?.(true);
                  debouncedFetchFirstPageSearchEntries?.();
                }}
                color={'secondary'}
                variant={'contained'}
              >
                {!!botSearch
                  ? alias === 'magazine'
                    ? 'Magazin durchsuchen'
                    : 'Nachrichten durchsuchen'
                  : 'Bot suchen lassen'}
              </Btn>
            </>}
          </Bx>}
          {!onStartRoute && canCreateContent && (flowSteps[alias] || onCreateContentClicked) && (
            <Bx ml={1}>
              <Btn
                size="large"
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                onClick={() => {
                  onCreateContentClicked ? onCreateContentClicked() : history.push(match.url + '/flow');
                }}
                color={'secondary'}
                variant={'contained'}
              >
                {createContentCaption || 'Neuer Beitrag'}
              </Btn>
            </Bx>
          )}
        </HeaderHeadbarContainer>
      </HeadBar>
      {!searchInactive && <HeaderFilter
        filterItem={filterItem}
        filterAlias={filterAlias}
        filtersVisible={filterVisible}
        filterWorking={filterWorking}
        filterSwitch={filterSwitch}
        searchObject={searchObject}
        searchTypes={searchTypes}
        selectedSearch={selectedSearch}
        searchResult={searchResult}
        handleClick={() => {}}
        setSelectedSearch={(a, b) => {
          setSelectedSearch?.(a, b);
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        resetSearchFilter={() => {
          resetSearchObject?.();
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onTypeChange={(thingType: string) => {
          changeQueryTypeAction?.(thingType);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onDestinationChange={(destinationAlias: string) => {
          changeQueryDestinationAction?.(destinationAlias);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onCategoryChange={(category: string, noToggle: boolean, parentCategoryId: string) => {
          changeQueryCategoriesAction?.(category, noToggle, parentCategoryId);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onSortChange={(sortId: string) => {
          changeQuerySortAction?.(sortId);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onSearchTypeChange={(searchTypeId: string) => {
          changeQuerySearchTypeAction?.(searchTypeId);
          fetchFirstPageSearchEntries?.();
        }}
        newsAllConcepts={newsAllConcepts}
        setNewsAllConcepts={setNewsAllConcepts}
      />}
    </>
  );
};

export const DashboardMobileHeader: React.FC<IDashboardHeaderProps & INavbarOpenProps> = ({
  alias,
  filterItem,
  filterAlias,
  routeToSearch,
  filterVisible,
  filterWorking,
  filterSwitch,
  placeholder,
  searchObject,
  searchTypes,
  selectedSearch,
  searchResult,
  changeQueryTerm,
  changeQueryTypeAction,
  changeQueryCategoriesAction,
  changeQueryDestinationAction,
  changeQuerySortAction,
  changeQuerySearchTypeAction,
  showFilterAction,
  hideFilterAction,
  fetchFirstPageSearchEntries,
  debouncedFetchFirstPageSearchEntries,
  debouncedBotFetchFirstPageSearchEntriesAction,
  setSearchWorkingAction,
  resetSearchObject,
  setSelectedSearch,
  canCreateContent,
  createContentCaption,
  onCreateContentClicked,
  open,
  setOpen,
  memoryList,
  memoryIds,
  setNewsAllConcepts,
  newsAllConcepts,
  botSearch,
  setBotSearchAction,
  setBotResponseAction,
  botAnswer
}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const theme = useTheme();
  const pathSplit = history.location.pathname.split('/');
  const onSearchRoute = pathSplit[pathSplit.length - 1] === 'search';
  const onStartRoute = history.location.pathname === '/start';
  const [useSearch, setUseSearch] = React.useState(onSearchRoute);

  const initialSearchRef = useRef(onSearchRoute);
  const initiallyEmpty = useRef(false);

  useEffect(() => {
    if ((!memoryIds || memoryIds?.length === 0) && !initiallyEmpty.current) {
      initiallyEmpty.current = true;
    }
  });

  useEffect(() => {
    if (initialSearchRef.current && searchObject && filterItem) {
      initialSearchRef.current = false;
      fetchFirstPageSearchEntries?.();
    }
  }, [initialSearchRef, fetchFirstPageSearchEntries, searchObject, filterItem]);

  const searchInactive = initiallyEmpty.current && !!memoryList && (!memoryIds || memoryIds?.length === 0);
  const botSearchActive = !!filterItem?.content?.botSearchActive;

  return (
    <MobileHeadBar>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={1}>
        <Grid item xs={3}>
          <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
            <IconBtn onClick={() => setOpen(open)}>
              <Hamburger width={30} height={30} size={20} toggled={open} label={open ? 'Menü schließen' : 'Menü öffnen'} />
            </IconBtn>
          </Bx>
        </Grid>
        <Grid item xs={6}>
          <Bx display="flex" alignItems="center" justifyContent="center" height="100%">
            <NavBarLogo to="/" src={MTOLogo.logoDarkSrc} />
          </Bx>
        </Grid>
        <Grid item xs={3}>
          <Bx display="flex" pl={1} alignItems="center" justifyContent="flex-end" height="100%">
            {<IconBtn
              onClick={() => {
                setUseSearch(!useSearch);
                if (filterVisible) hideFilterAction?.();
              }}
            >
              <FontAwesomeIcon icon={useSearch ? faTimes : faSearch} />
            </IconBtn>}
            {!onStartRoute && canCreateContent && (flowSteps[alias] || onCreateContentClicked) && (
              <Bx mx={1}>
                <IconBtn
                  size="small"
                  color="secondary"
                  variant="contained"
                  onClick={() => (onCreateContentClicked ? onCreateContentClicked() : history.push(match.url + '/flow'))}
                  style={{ borderRadius: theme.shape.borderRadius }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </IconBtn>
              </Bx>
            )}
          </Bx>
        </Grid>
      </Bx>
      {!searchInactive && <Collapse in={useSearch}>
        <>
          <Bx display="flex" height="100%" minHeight={64} alignItems="center" py={1}>
            <Bx display="flex" width="100%" zIndex={2}>
              <Bx pl={1} flexGrow={1} flexShrink={1} flexBasis="auto">
                <HeadSearchInput
                  searchIcon={!!botSearch && <FontAwesomeIcon icon={faMessageBot} />}
                  input={{
                    id: 'head-search',
                    value: searchObject?.queryTerm,
                    placeholder: alias === 'start'
                      ? filterItem?.content?.title || 'Medtec Online durchsuchen'
                      : !!botSearch
                        ? 'Bot suchen lassen'
                        : placeholder || 'Durchsuchen',
                    onChange: e => {
                      const val = e.target.value;
                      if (botSearch && !!botAnswer && val?.length > 0) {
                        setBotResponseAction?.('', '');
                      }
                      changeQueryTerm?.(val, !!botSearch);
                      if (!onSearchRoute && routeToSearch) {
                        let searchUrl = match.url + '/search';
                        if (alias === 'news' && val.length > 0) {
                          searchUrl += '?ref=/news';
                        }
                        history.push(searchUrl);
                      }
                      if ((!botSearch || val?.length ===0) && val !== undefined && (val?.length > 1 || val?.length === 0)) {
                        debouncedFetchFirstPageSearchEntries?.();
                      }
                    },
                    onKeyPress: e => {
                      if (!!botSearch && e.key === 'Enter') {
                        setSearchWorkingAction?.(true);
                        debouncedBotFetchFirstPageSearchEntriesAction?.()
                      }
                    }
                  }}
                  inputLabel={{ htmlFor: 'head-search' }}
                  clearOnClick={() => {
                    const referrer = getReferrer(history.location.search);
                    if (referrer) {
                      if (alias === 'news' || alias === 'magazine') {
                        changeQueryTerm?.('');
                        if (botSearch && !!botAnswer) {
                          setBotResponseAction?.('', '');
                        }
                        if (!botSearch) {
                          resetSearchObject?.();
                        }
                        setSearchWorkingAction?.(true);
                        debouncedFetchFirstPageSearchEntries?.();
                        if (!botSearch) {
                          history.push(referrer);
                        }
                      } else {
                        history.push(referrer);
                      }
                    } else {
                      changeQueryTerm?.('', !!botSearch);
                      if (botSearch && !!botAnswer) {
                        setBotResponseAction?.('', '');
                      }
                      debouncedFetchFirstPageSearchEntries?.();
                      if (!onSearchRoute && routeToSearch) {
                        history.push(match.url + '/search');
                      }
                    }
                  }}
                />
              </Bx>
              <Bx display={'flex'}>
                {!botSearch && <IconBtn
                  size="small"
                  color={filterVisible ? 'primary' : 'default'}
                  variant={filterVisible ? 'contained' : 'outlined'}
                  onClick={() => {
                    if (filterVisible) {
                      hideFilterAction?.();
                    } else {
                      showFilterAction?.();
                    }
                  }}
                  shape="rounded"
                  title="Filtern"
                >
                  <FontAwesomeIcon icon={faSlidersV} />
                </IconBtn>}
                {(alias === 'news' || alias === 'magazine') && botSearchActive && <>
                  {botSearch && <IconBtn
                    variant="contained" shape="circular"
                    style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
                    size="small"
                    onClick={() => {
                      setSearchWorkingAction?.(true);
                      setBotResponseAction?.('', '');
                      debouncedFetchFirstPageSearchEntries?.();
                    }}
                    title='Abschicken'
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                  </IconBtn>}
                  <IconBtn
                    style={{ marginLeft: 8 }}
                    size="small"
                    onClick={() => {
                      if (!botSearch && !!botSearchActive) {
                        hideFilterAction?.();
                        resetSearchObject?.();
                        if (!onSearchRoute && routeToSearch) {
                          history.push(match.url + '/search');
                        }
                      }
                      setBotSearchAction?.();
                      changeQueryTerm?.('', !botSearch && !!botSearchActive);
                      if (botSearch && !!botAnswer) {
                        setBotResponseAction?.('', '');
                      }
                      setSearchWorkingAction?.(true);
                      debouncedFetchFirstPageSearchEntries?.();
                    }}
                    color={'secondary'}
                    variant={'contained'}
                    shape="rounded"
                    title={!!botSearch
                      ? alias === 'magazine'
                        ? 'Magazin durchsuchen'
                        : 'Nachrichten durchsuchen'
                      : 'Bot suchen lassen'}
                  >
                    {!!botSearch ? <FontAwesomeIcon icon={faSearch} /> : <FontAwesomeIcon icon={faMessageBot} />}
                  </IconBtn>
                </>}
              </Bx>
            </Bx>
          </Bx>
        </>
      </Collapse>}
      {!searchInactive && <HeaderFilter
        smallVariant={true}
        filterItem={filterItem}
        filterAlias={filterAlias}
        filtersVisible={filterVisible}
        filterWorking={filterWorking}
        filterSwitch={filterSwitch}
        searchObject={searchObject}
        searchTypes={searchTypes}
        selectedSearch={selectedSearch}
        searchResult={searchResult}
        handleClick={() => {}}
        setSelectedSearch={(a, b) => {
          setSelectedSearch?.(a, b);
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        resetSearchFilter={() => {
          resetSearchObject?.();
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onTypeChange={(thingType: string) => {
          changeQueryTypeAction?.(thingType);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onDestinationChange={(destinationAlias: string) => {
          changeQueryDestinationAction?.(destinationAlias);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onCategoryChange={(category: string, noToggle: boolean, parentCategoryId: string) => {
          changeQueryCategoriesAction?.(category, noToggle, parentCategoryId);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onSortChange={(sortId: string) => {
          changeQuerySortAction?.(sortId);
          fetchFirstPageSearchEntries?.();
          if (!onSearchRoute && routeToSearch) {
            history.push(match.url + '/search');
          }
        }}
        onSearchTypeChange={(searchTypeId: string) => {
          changeQuerySearchTypeAction?.(searchTypeId);
          fetchFirstPageSearchEntries?.();
        }}
        newsAllConcepts={newsAllConcepts}
        setNewsAllConcepts={setNewsAllConcepts}
      />}
    </MobileHeadBar>
  );
};

export interface IConnectedDashboardHeader {
  alias: string;
  placeholder?: string;
  onCreateContentClicked?: () => void;
  routeToSearch?: boolean;
}

export const ConnectedDashboardHeader: React.FC<IConnectedDashboardHeader> = ({ alias, placeholder, onCreateContentClicked, routeToSearch }) => {
  const dispatch = useDispatch();
  const flowSearch = useSelector(state => state.filter?.flowSearch);
  if (flowSearch) {
    dispatch(fetchFilterRequestAction({ alias }));
    dispatch(setSelectedSearchAction({ searchAlias: alias }));
  }
  const isMobile = useIsMobile();
  const navBarOpen = useSelector(state => state.foundation?.navbarOpen);
  const result = useSelector(state => state.search?.result);
  const config = useSelector(state => state.foundation?.appconfig?.[alias]);
  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const communications = useSelector(state => state.communication?.communications);
  const communicationsWorking = useSelector(state => state.communication?.communicationsWorking);
  const searchTypes = useSelector(state => state.filter?.filterItem?.content?.searchTypes);
  const selectedSearch = useSelector(state => state.filter?.selectedSearch);
  const filterSwitch = useSelector(state => state.filter?.filterItem?.content?.filterSwitch);
  const setNavbarOpen = () => dispatch(setNavbarOpenAction(!navBarOpen));
  const newsAllConcepts = useSelector(state => state.news?.allConcepts);
  const botSearch = useSelector(state => state.filter?.botSearch);
  const botAnswer = useSelector(state => state.news?.botAnswer);

  const [dispatcher, setDispatcher] = useState({} as any);
  const { searchObject, filterVisible, filterItem, filterAlias, filterWorking } = useFilterState();

  useEffect(() => {
    if (alias === 'news') {
      setDispatcher(() => ({
        changeQueryTermAction: (term: string) => {
          dispatch(changeQueryTermAction?.({ term }));
        },
        changeQueryTypeAction: () => {},
        changeQueryDestinationAction: () => {},
        changeQueryCategoriesAction: (category: string, noToggle: boolean, parentCategoryId: string) =>
          dispatch(changeQueryCategoriesAction?.({ category, noToggle, parentCategoryId })),
        changeQuerySortAction: (sortId: string) => dispatch(changeQuerySortAction?.({ sortId })),
        changeQuerySearchTypeAction: (searchTypeId: 'combined' | 'combined2' | 'ontology' | 'elastic') => dispatch(changeQuerySearchTypeAction?.({ searchTypeId })),
        resetSearchObjectAction: () => {
          dispatch(resetSearchObjectAction?.({ keepFilter: true, keepFilterAlias: true }));
        },
        showFilterAction: () => dispatch(showFilterAction?.({})),
        hideFilterAction: () => dispatch(hideFilterAction?.({})),
        fetchFirstPageSearchEntriesAction: () => {
          dispatch(fetchFirstPageNewsRequestAction?.());
        },
        debouncedFetchFirstPageSearchEntriesAction: _debounce(() => {
          dispatch(fetchFirstPageNewsRequestAction?.());
        }, 350),
        debouncedBotFetchFirstPageSearchEntriesAction: _debounce(() => {
          dispatch(fetchFirstPageNewsRequestAction?.());
        }, 1000),
        setSelectedSearchAction: (searchAlias: string, keepQuery: boolean) => { // load different search by alias
          if (searchAlias) {
            dispatch(setBotSearchAction({ botSearch: false }));
            dispatch(resetSearchObjectAction({ keepFilter: true, keepQuery }));
            dispatch(clearSearchEntriesAction({}));
            dispatch(fetchFilterRequestAction({ alias: searchAlias }));
            dispatch(setSelectedSearchAction({ searchAlias }));
            dispatch(fetchFirstPageNewsRequestAction?.());
          }
        },
        setNewsAllConcepts: () => {
          dispatch(setNewsAllConcepts?.());
          dispatch(fetchFirstPageNewsRequestAction?.());
        },
        setSearchWorking: (working: boolean) => dispatch(setNewsWorking({ working })),
        setBotSearchAction: () => dispatch(setBotSearchAction({})),
        setBotResponseAction: (botAnswer: string, botRequestId: string) => dispatch(botResponseEndAction?.({response:botAnswer, request_id: botRequestId})),
      }));
    } else {
      setDispatcher(() => ({
        changeQueryTermAction: (term: string, noFilterAction?: boolean) => {
          dispatch(changeQueryTermAction?.({ term }));
          if (!noFilterAction) {
            dispatch(showFilterAction?.({}));
          }
        },
        changeQueryTypeAction: (thingType: string) => dispatch(changeQueryTypeAction?.({ thingType })),
        changeQueryDestinationAction: (destinationAlias: string) => dispatch(changeQueryDestinationAction?.({ destinationAlias })),
        changeQueryCategoriesAction: (category: string, noToggle: boolean, parentCategoryId: string) =>
          dispatch(changeQueryCategoriesAction?.({ category, noToggle, parentCategoryId })),
        changeQuerySortAction: (sortId: string) => dispatch(changeQuerySortAction?.({ sortId })),
        resetSearchObjectAction: () => dispatch(resetSearchObjectAction?.({ keepFilter: true, keepFilterAlias: true })),
        showFilterAction: () => dispatch(showFilterAction?.({})),
        hideFilterAction: () => dispatch(hideFilterAction?.({})),
        fetchFirstPageSearchEntriesAction: () => {
          dispatch(fetchFirstPageSearchEntriesRequestAction?.({}));
        },
        debouncedFetchFirstPageSearchEntriesAction: _debounce(() => {
          dispatch(fetchFirstPageSearchEntriesRequestAction?.({}));
        }, 350),
        debouncedBotFetchFirstPageSearchEntriesAction: _debounce(() => {
          dispatch(fetchFirstPageSearchEntriesRequestAction?.({}));
        }, 1000),
        setSelectedSearchAction: (searchAlias: string, keepQuery: boolean) => {
          if (searchAlias) {
            dispatch(setBotSearchAction({ botSearch: false }));
            dispatch(resetSearchObjectAction({ keepFilter: true, keepQuery }));
            dispatch(clearSearchEntriesAction({}));
            dispatch(fetchFilterRequestAction({ alias: searchAlias }));
            dispatch(setSelectedSearchAction({ searchAlias }));
            dispatch(fetchFirstPageSearchEntriesRequestAction?.({}));
          }
        },
        setSearchWorking: (working: boolean) => dispatch(setSearchWorkingAction({ working })),
        setBotSearchAction: () => dispatch(setBotSearchAction({})),
        setBotResponseAction: (botAnswer: string, botRequestId: string) => dispatch(botResponseEndAction?.({response:botAnswer, request_id: botRequestId})),
      }));
    }
  }, [dispatch, alias]);

  let canCreateContent = config?.canCreateContent;
  if (config?.showSidebar && config.sidebarCreate && (communications?.length || communicationsWorking)) {
    canCreateContent = false;
  }

  const DashboardHeaderProps = {
    routeToSearch: routeToSearch === false ? false : true,
    alias: alias,
    canCreateContent: canCreateContent,
    createContentCaption: config?.createContentCaption,
    placeholder: placeholder,
    filterItem: filterItem,
    filterAlias: filterAlias,
    filterVisible: filterVisible,
    filterWorking: filterWorking,
    filterSwitch: filterSwitch,
    searchObject: searchObject,
    searchTypes: searchTypes,
    selectedSearch: selectedSearch,
    searchResult: result,
    changeQueryTerm: dispatcher.changeQueryTermAction,
    changeQueryTypeAction: dispatcher.changeQueryTypeAction,
    changeQueryDestinationAction: dispatcher.changeQueryDestinationAction,
    changeQueryCategoriesAction: dispatcher.changeQueryCategoriesAction,
    changeQuerySortAction: dispatcher.changeQuerySortAction,
    changeQuerySearchTypeAction: dispatcher.changeQuerySearchTypeAction,
    fetchFirstPageSearchEntries: dispatcher.fetchFirstPageSearchEntriesAction,
    debouncedFetchFirstPageSearchEntries: dispatcher.debouncedFetchFirstPageSearchEntriesAction,
    debouncedBotFetchFirstPageSearchEntriesAction: dispatcher.debouncedBotFetchFirstPageSearchEntriesAction,
    resetSearchObject: dispatcher.resetSearchObjectAction,
    showFilterAction: dispatcher.showFilterAction,
    hideFilterAction: dispatcher.hideFilterAction,
    setSelectedSearch: dispatcher.setSelectedSearchAction,
    setNewsAllConcepts: dispatcher.setNewsAllConcepts,
    setSearchWorkingAction: dispatcher.setSearchWorking,
    memoryList: config?.dashboardMemoryLists,
    memoryIds: memoryList?.map(m => m.elementId),
    onCreateContentClicked,
    newsAllConcepts: newsAllConcepts,
    botSearch: botSearch,
    setBotSearchAction: dispatcher.setBotSearchAction,
    setBotResponseAction: dispatcher.setBotResponseAction,
    botAnswer: botAnswer
  };

  if (isMobile) return <DashboardMobileHeader open={!!navBarOpen} setOpen={setNavbarOpen} {...DashboardHeaderProps} />;
  else return <DashboardHeader {...DashboardHeaderProps} />;
};
