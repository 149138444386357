import { callPost } from '@curry-group/data-addons';
import { useSelector } from 'react-redux';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { ISearchRequestBody } from '../../../model/search/SearchRequest';
import { IListUser, ISearchResult } from '../../../model/search/SearchResult';

import {
  fetchFirstPageSearchEntriesRequestAction,
  fetchFirstPageSearchEntriesErrorAction,
  fetchFirstPageSearchEntriesSuccessAction,
  fetchNextPageSearchEntriesRequestAction,
  fetchNextPageSearchEntriesErrorAction,
  fetchNextPageSearchEntriesSuccessAction,
  fetchPlayersByParentIdRequestAction,
  fetchPlayersByParentIdErrorAction,
  fetchPlayersByParentIdSuccessAction,
} from '../../actions/search';
import { api } from '../../api';
import { setAvatarAssetsAction } from '../../actions/foundation';
import { subscribeToSignalR, unsubscribeFromSignalR } from '../../reducer/communication';
import { resetBotFeedbackAction } from '../../reducer/detail';
import { resetBotRequestAction } from '../../actions/news';

export function useSearchState() {
  return useSelector(state => state.search);
}

export function* fetchFirstPageSearchEntriesWorker() {
  try {
    yield call(waitFor, (state: { filter: { filterItem: any } }) => state.filter.filterItem);
    const searchParams = yield select(state => state.filter.searchObject);
    const searchTypes = yield select(state => state.filter.filterItem.content.searchTypes);
    const autoSuggestActive = yield select(state => state.filter.filterItem.content.autoSuggestActive);
    const lastUpdatedFilter = yield select(state => state.filter.lastUpdatedFilter);
    const noUpdateCategories = yield select(state => state.filter.noUpdateCategories);
    const ownProfileId = yield select(state => state.foundation.profile.profileId);
    const memoryList = yield select(state => state.foundation?.profile?.memoryList);
    const pageSize = yield select(state => state.search.queryPageSize);
    const pageSkip = yield select(state => state.search.querySkip);
    const excludeOwn = yield select(state => state.filter.filterItem.content.excludeOwn);
    const containedIdsChats = yield select(state => state.filter.filterItem.content.containedIdsChats);
    const ownChats = yield select(state => state.communication.communications);
    const memoryIdSearch = yield select(state => state.filter.filterItem.content.memoryIdSearch);
    const searchInMessages = yield select(state => state.filter.filterItem.content.searchInMessages);
    const queryAdditionalId = yield select(state => state.filter.filterItem.content.queryAdditionalId);
    const ownCommunications = yield select(state => state.foundation.groups);
    const botSearch = yield select(state => state.filter?.botSearch);
    const userId = yield select(state => state.foundation?.profile?.userId);

    const fuzzyQueryActive = yield select(state => state.filter.filterItem.content.fuzzyQueryActive);
    const fuzzyFuzziness = yield select(state => state.filter.filterItem.content.fuzzyFuzziness);
    const fuzzyMaxExpansions = yield select(state => state.filter.filterItem.content.fuzzyMaxExpansions);
    const fuzzyPrefixLength = yield select(state => state.filter.filterItem.content.fuzzyPrefixLength);
    const fuzzyTranspositions = yield select(state => state.filter.filterItem.content.fuzzyTranspositions);

    const effectiveParams: ISearchRequestBody = {
      ...searchParams,
      querySize: pageSize + 1,
      querySkip: pageSkip,
      lastUpdatedFilter: lastUpdatedFilter,
      noUpdateCategories: noUpdateCategories,
      searchTypes,
    };
    if (searchParams.searchTypes) {
      effectiveParams.searchTypes = searchParams.searchTypes;
    }
    if (excludeOwn) {
      effectiveParams.excludedIDs = ownCommunications;
    }
    if (memoryIdSearch) {
      effectiveParams.containedIDs = memoryList?.map(m => m.elementId);
    }
    if (queryAdditionalId) {
      effectiveParams.queryAdditionalId = queryAdditionalId;
    }
    if (containedIdsChats) {
      effectiveParams.containedIDs = ownChats?.map(m => m._id);
      effectiveParams.containedIdsChats = true;
    }
    if (searchInMessages) {
      effectiveParams.searchInMessages = true;
    }
    if (fuzzyQueryActive) {
      effectiveParams.fuzzyQueryActive = fuzzyQueryActive;
      effectiveParams.fuzzyFuzziness = fuzzyFuzziness;
      effectiveParams.fuzzyMaxExpansions = fuzzyMaxExpansions;
      effectiveParams.fuzzyPrefixLength = fuzzyPrefixLength;
      effectiveParams.fuzzyTranspositions = fuzzyTranspositions;
    }

    let result: ISearchResult;
    let skipQuery = false;
    let botCommId = '';
    if (autoSuggestActive) {
      // no csrf token needed, post does not manipulate data
      result = yield callPost(api.v1.players.autosuggest, effectiveParams);
    } else {
      const filterAlias = yield select(state => state.filter.filterAlias);
      const currentAlias = yield select(state => state.flow.currentAlias);
      if (filterAlias && currentAlias) {
        if (filterAlias === 'find-experts' && currentAlias === 'add-participants') {
          const categories = yield select(state => state.detail?.item?.categories);
          if (categories && categories.length > 0) {
            effectiveParams.competenceCategories = categories.map(cat => cat.categoryId);
          }
        } else if (filterAlias === 'find-experts' && currentAlias === 'groups-projects') {
          const categories = yield select(state => state.flow?.flowData?.categories);
          if (categories && categories.length > 0) {
            effectiveParams.competenceCategories = categories;
          }
        } else if (filterAlias === 'find-experts' && currentAlias === 'createmeeting') {
          const users = yield select(state => state.communication?.participants);
          const acceptedUsers = users.filter(u => u.status === 'accepted');
          if (acceptedUsers && acceptedUsers.length > 0) {
            const profileIds = acceptedUsers
              .map(p => {
                return p.userResolved ? p.userResolved._id : '';
              })
              .filter(Boolean);
            effectiveParams.containedIDs = profileIds.filter(u => u !== ownProfileId);
            if (!effectiveParams.containedIDs || effectiveParams.containedIDs.length === 0) {
              skipQuery = true;
            }
          } else {
            skipQuery = true;
          }
        } else if (filterAlias === 'find-chat' && currentAlias === 'chat') {
          effectiveParams.excludeChatNotAllowed = true;
        }
      } else {
        const profile = yield select(state => state.foundation.profile);
        if (profile) {
          if (profile.competenceCategories && profile.competenceCategories.length > 0) {
            effectiveParams.competenceCategories = profile.competenceCategories.map(cat => cat.categoryId);
          }
          if (profile.interestCategories && profile.interestCategories.length > 0) {
            effectiveParams.interestCategories = profile.interestCategories.map(cat => cat.categoryId);
          }
        }
      }
      if (botSearch) {
        yield put(resetBotRequestAction());
        yield put(resetBotFeedbackAction({}));
        effectiveParams.querySearchType = 'botsearch';
        botCommId = 'no_comm_XB_NewsRequest_' + userId;
        yield put(subscribeToSignalR({ id: botCommId }));
      }
      if (!skipQuery) {
        // no csrf token needed, post does not manipulate data
        result = yield callPost(api.search.dynamic, effectiveParams);
      } else {
        result = {
          aggregations: {},
          aggregations_types: {},
          aggregations_fields: {},
          aggregations_destinations: {},
          count: 0,
          hits: { hits: [], total: 0 },
          list_users: {},
        };
      }
    }

    let listUsers: { [userId: string]: IListUser } = {};
    listUsers = { ...listUsers, ...result.list_users };
    if (Object.keys(listUsers).length > 0) {
      yield put(setAvatarAssetsAction({ idAssetDict: listUsers }));
    }

    yield put(fetchFirstPageSearchEntriesSuccessAction({ result }));
    if (botSearch) {
      yield put(unsubscribeFromSignalR({ id: botCommId }));
    }
  } catch (e: any) {
    yield put(fetchFirstPageSearchEntriesErrorAction({ message: e.message }));
  }
}

export function* fetchNextPageSearchEntriesWorker() {
  try {
    yield call(waitFor, (state: { filter: { filterItem: any } }) => state.filter.filterItem);
    const searchParams = yield select(state => state.filter.searchObject);
    const searchTypes = yield select(state => state.filter.filterItem.content.searchTypes);
    const autoSuggestActive = yield select(state => state.filter.filterItem.content.autoSuggestActive);
    const lastUpdatedFilter = yield select(state => state.filter.lastUpdatedFilter);
    const noUpdateCategories = yield select(state => state.filter.noUpdateCategories);
    const pageSize = yield select(state => state.search.queryPageSize);
    const pageSkip = yield select(state => state.search.querySkip);
    const excludeOwn = yield select(state => state.filter.filterItem.content.excludeOwn);
    const containedIdsChats = yield select(state => state.filter.filterItem.content.containedIdsChats);
    const ownChats = yield select(state => state.communication.communications);
    const memoryIdSearch = yield select(state => state.filter.filterItem.content.memoryIdSearch);
    const searchInMessages = yield select(state => state.filter.filterItem.content.searchInMessages);
    const ownCommunications = yield select(state => state.foundation.groups);
    const ownProfileId = yield select(state => state.foundation.profile.profileId);
    const queryAdditionalId = yield select(state => state.filter.filterItem.content.queryAdditionalId);
    const memoryList = yield select(state => state.foundation?.profile?.memoryList);

    const fuzzyQueryActive = yield select(state => state.filter.filterItem.content.fuzzyQueryActive);
    const fuzzyFuzziness = yield select(state => state.filter.filterItem.content.fuzzyFuzziness);
    const fuzzyMaxExpansions = yield select(state => state.filter.filterItem.content.fuzzyMaxExpansions);
    const fuzzyPrefixLength = yield select(state => state.filter.filterItem.content.fuzzyPrefixLength);
    const fuzzyTranspositions = yield select(state => state.filter.filterItem.content.fuzzyTranspositions);

    const effectiveParams = {
      ...searchParams,
      querySize: pageSize + 1,
      querySkip: pageSkip,
      lastUpdatedFilter: lastUpdatedFilter,
      noUpdateCategories: noUpdateCategories,
      searchTypes,
    };
    if (searchParams.searchTypes) {
      effectiveParams.searchTypes = searchParams.searchTypes;
    }
    if (excludeOwn) {
      effectiveParams.excludedIDs = ownCommunications;
    }
    if (memoryIdSearch) {
      effectiveParams.containedIDs = memoryList?.map(m => m.elementId);
    }
    if (queryAdditionalId) {
      effectiveParams.queryAdditionalId = queryAdditionalId;
    }
    if (containedIdsChats) {
      effectiveParams.containedIDs = ownChats?.map(m => m._id);
      effectiveParams.containedIdsChats = true;
    }
    if (searchInMessages) {
      effectiveParams.searchInMessages = true;
    }
    if (fuzzyQueryActive) {
      effectiveParams.fuzzyQueryActive = fuzzyQueryActive;
      effectiveParams.fuzzyFuzziness = fuzzyFuzziness;
      effectiveParams.fuzzyMaxExpansions = fuzzyMaxExpansions;
      effectiveParams.fuzzyPrefixLength = fuzzyPrefixLength;
      effectiveParams.fuzzyTranspositions = fuzzyTranspositions;
    }

    let result: ISearchResult;
    let skipQuery = false;
    if (autoSuggestActive) {
      // no csrf token needed, post does not manipulate data
      result = yield callPost(api.v1.players.autosuggest, effectiveParams);
    } else {
      const filterAlias = yield select(state => state.filter.filterAlias);
      const currentAlias = yield select(state => state.flow.currentAlias);
      if (filterAlias && currentAlias) {
        if (filterAlias === 'find-experts' && currentAlias === 'add-participants') {
          const categories = yield select(state => state.detail?.item?.categories);
          if (categories && categories.length > 0) {
            effectiveParams.competenceCategories = categories.map(cat => cat.categoryId);
          }
        } else if (filterAlias === 'find-experts' && currentAlias === 'groups-projects') {
          const categories = yield select(state => state.flow?.flowData?.categories);
          if (categories && categories.length > 0) {
            effectiveParams.competenceCategories = categories;
          }
        } else if (filterAlias === 'find-experts' && currentAlias === 'createmeeting') {
          const users = yield select(state => state.communication?.participants);
          const acceptedUsers = users.filter(u => u.status === 'accepted');
          if (acceptedUsers && acceptedUsers.length > 0) {
            const profileIds = acceptedUsers
              .map(p => {
                return p.userResolved ? p.userResolved._id : '';
              })
              .filter(Boolean);
            effectiveParams.containedIDs = profileIds.filter(u => u !== ownProfileId);
            if (!effectiveParams.containedIDs || effectiveParams.containedIDs.length === 0) {
              skipQuery = true;
            }
          } else {
            skipQuery = true;
          }
        } else if (filterAlias === 'find-chat' && currentAlias === 'chat') {
          effectiveParams.excludeChatNotAllowed = true;
        }
      } else {
        const profile = yield select(state => state.foundation.profile);
        if (profile) {
          if (profile.competenceCategories && profile.competenceCategories.length > 0) {
            effectiveParams.competenceCategories = profile.competenceCategories.map(cat => cat.categoryId);
          }
          if (profile.interestCategories && profile.interestCategories.length > 0) {
            effectiveParams.interestCategories = profile.interestCategories.map(cat => cat.categoryId);
          }
        }
      }
      if (!skipQuery) {
        // no csrf token needed, post does not manipulate data
        result = yield callPost(api.search.dynamic, effectiveParams);
      } else {
        result = {
          aggregations: {},
          aggregations_types: {},
          aggregations_fields: {},
          aggregations_destinations: {},
          count: 0,
          hits: { hits: [], total: 0 },
          user_avatars: {},
        };
      }
    }

    let listUsers: { [userId: string]: IListUser } = {};
    listUsers = { ...listUsers, ...result.list_users };
    if (Object.keys(listUsers).length > 0) {
      yield put(setAvatarAssetsAction({ idAssetDict: listUsers }));
    }

    yield put(fetchNextPageSearchEntriesSuccessAction({ result }));
  } catch (e: any) {
    yield put(fetchNextPageSearchEntriesErrorAction({ message: e.message }));
  }
}

export function* watcher() {
  yield takeLatest(fetchFirstPageSearchEntriesRequestAction.type, fetchFirstPageSearchEntriesWorker);
  yield takeLatest(fetchNextPageSearchEntriesRequestAction.type, fetchNextPageSearchEntriesWorker);
  yield takeLatest(fetchPlayersByParentIdRequestAction.type, fetchPlayersByParentIdWorker);
}

function* waitFor(selector) {
  if (yield select(selector)) return; // return if selector true
  while (true) {
    yield take('*'); // wait with empty task
    if (yield select(selector)) return; // return if selector true
  }
}

function* fetchPlayersByParentIdWorker(action: ReturnType<typeof fetchPlayersByParentIdRequestAction>) {
  try {
    // no csrf token needed, post does not manipulate data
    const result = yield callPost(api.query(api.v1.players.byparentid, { type: action.payload.type, id: action.payload.id }), { queryDisabledItems: true });
    yield put(fetchPlayersByParentIdSuccessAction({ result }));
  } catch (e: any) {
    yield put(fetchPlayersByParentIdErrorAction({ message: e.message }));
  }
}
